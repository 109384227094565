<template>
  <section v-if="isSignedInUser" id="Dashboard">
    <div class="container">
        <div class="col col-md-8 col-lg-6 col-xl-4 col-xxl-4">
        <h1>Dashboard</h1>
        <p>Click “Start New Quiz” to begin new Client/Project .</p>
        <div v-if="isSignedInUser" class="inline-list-item list-unstyled">
            <router-link to="/start" class="btn btn-primary me-2">Start New Quiz</router-link>
            <p v-if="getCurrentUserInRole('Task.Admin')" class="mt-3">To edit quiz, click the options below.</p>
        </div>
        <!-- heading -->
        <ul class="list-group">
            <li class="list-group-item list-group-header d-flex justify-content-between align-items-center my-1 py-1">
                <div></div>
                <div>
                    <span class="text-success item-count-published">Published</span>/<span class="item-count-total">Total</span>
                </div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
                <div class="ms-2 me-auto">
                    <router-link to="/fragrances" class="link link-primary">Fragrances</router-link>
                </div>
                <div v-if="isFragrancesLoading" class="text-center">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else>
                    <span class="text-success item-count-published">{{ publishedFragranceCount }}</span>/<span class="item-count-total">{{ fragranceCount }}</span>
                </div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
                <div class="ms-2 me-auto">
                    <router-link to="/questions" class="link link-primary">Quiz Questions</router-link>
                </div>
                <div v-if="isQuestionsLoading" class="text-center">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else class="">
                    <span class="text-success item-count-published">{{ publishedQuestionCount }}</span>/<span class="item-count-total">{{ totalQuestionCount }}</span>
                </div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link to="/adjectives" class="link link-primary">Adjectives</router-link>
            </div>
            <div v-if="isAdjectivesLoading" class="text-center">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            <div v-else class=""><span class="item-count">{{ adjectiveCount }}</span></div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link to="/users" class="link link-primary">Users</router-link>
            </div>
            <div v-if="isUsersLoading" class="text-center">
                    <div class="spinner-border spinner-border-sm text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            <div v-else class=""><span class="item-count">{{ userCount }}</span></div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link to="/providers" class="link link-primary">Scent Providers</router-link>
            </div>
            <div v-if="isProvidersLoading" class="text-center">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class=""><span class="item-count">{{ providerCount }}</span></div>
            </li>
            <li v-if="(getCurrentUserInRole('Task.Manager') || getCurrentUserInRole('Task.User')) && currentUserProvider != null" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link :to="'/fragrance-inventory/' + currentUserProvider.id" class="link link-primary">Fragrance Inventory</router-link>
            </div>
            <div v-if="isFragrancesLoading" class="text-center">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class=""><span class="item-count">{{ providerFragranceCount }}</span></div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager') || getCurrentUserInRole('Task.User')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link to="/invites" class="link link-primary">Invites</router-link>
            </div>
            <div v-if="isInvitesLoading" class="text-center">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class=""><span class="item-count">{{ inviteCount }}</span></div>
            </li>
            <li v-if="getCurrentUserInRole('Task.Admin') || getCurrentUserInRole('Task.Manager') || getCurrentUserInRole('Task.User')" class="list-group-item d-flex justify-content-between align-items-center my-2 py-3">
            <div class="ms-2 me-auto">
                <router-link to="/results" class="link link-primary">Quiz History</router-link>
            </div>
            <div v-if="isQuizResultsLoading" class="text-center">
                <div class="spinner-border spinner-border-sm text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div v-else class=""><span class="item-count">{{ resultCount }}</span></div>
            </li>
        </ul>
      </div>
      <div class="">
        <div v-if="account" class="ms-3 my-5">
          <a class="link link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
            View Profile: {{ account.name }}
          </a>
          <div class="collapse" id="collapseExample">
            <div class="">
              <!-- ACCOUNT: {{ account }} -->
              <ul>
                <li><span class="">User Name: </span><span class="fw-bold">{{ account.name }}</span></li>
                <li><span class="">Email Address: </span><span class="fw-bold">{{ account.email }}</span></li>
                <li v-if="getCurrentUserInRole('Task.Admin')"><span class="">Role: </span><span class="fw-bold">Admin</span></li>
                <li v-else-if="getCurrentUserInRole('Task.Manager')"><span class="">Role: </span><span class="fw-bold">Manager</span></li>
                <li v-else><span class="">Role: </span><span class="fw-bold">Salesperson</span></li>
                <li><span class="">Provider: </span><span class="fw-bold">{{ currentUserProvider.name }}</span></li>
              </ul>
            </div>
            <div class="">
                <h3>Actions</h3>
                <div class="d-none my-2">
                    <button class="btn btn-primary" @click="reSyncData">Reset Data</button>
                </div>
                <div class="my-2">
                    <button class="btn btn-primary" @click="clearStorage">Clear Cache</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template> 

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Dashboard',
  components: {
  },
  data() {
    return {
      crumbs: [{
        name: 'Dashboard',
        url: '/dashboard'
            }],
      payload:  '',
      inviteCount: 0,
      resultCount: 0,
      userCount: 0,
      providerFragranceCount: 0,
      currentSort: '',
      currentSortDir: 'asc',
      isLoading: false,
      isQuestionsLoading: false,
      isFragrancesLoading: false,
      isInvitesLoading: false,
      isQuizResultsLoading: false,
      isUsersLoading: false,
      isAdjectivesLoading: false,
      isProvidersLoading: false,
      currentExtendedUser: {},
      currentUserProvider: {},
    }
  },
  provide() {
    return {
    }
  },
  mounted() {
    this.initDashboard();
  },
  computed: {
    ...mapGetters({
        fragranceCount: 'fragrancesExtendedModule/count',
        publishedFragranceCount: 'fragrancesExtendedModule/publishedCount',
        providerFragranceCount: 'fragrancesExtendedModule/countByProvider',
        adjectiveCount: 'adjectivesModule/count',
        providerCount: 'providersModule/count',
        providerDetail: 'providersModule/getProviderById',
        totalUsersCount: 'usersModule/count',
        userCountByProvider: 'usersModule/countByProvider',
        totalUsers: 'usersModule/getUsers',
        totalInviteCount: 'invitesModule/count',
        inviteCountByProvider: 'invitesModule/countByProvider',
        inviteCountBySalesperson: 'invitesModule/countBySalesperson',
        totalQuestionCount: 'questionsModule/totalCount',
        publishedQuestionCount: 'questionsModule/publishedCount',
        totalQuizResultCount: 'quizResultsModule/totalCount',
        resultCountByProvider: 'quizResultsModule/countByProvider',
        resultCountBySalesperson: 'quizResultsModule/countBySalesperson',
        getCurrentUserInRole: 'getCurrentUserInRole',
        getUserByEmailAddress: 'usersModule/getUserByEmailAddress',
        isSignedInUser: 'isSignedInUser',
        account: 'getCurrentUser',

    })
    // account() {
    //    return auth.user;
    //  },
    //  isAdmin() {
    //    return this.$store.getters.isAdmin;
    //  },
    //  isManager() {
    //    return this.$store.getters.isManager;
    //  }
  },
  methods: {
    async initDashboard() {
        this.isUsersLoading = true;
        await this.$store.dispatch("usersModule/fetchAllUsers").then((uResults) => {
            this.currentExtendedUser = this.getUserByEmailAddress(this.account?.username);
            console.log(this.currentExtendedUser);
            console.log('Users retrieved...');
            console.log(uResults);
            this.loadData(); 
        });
          
    },
    async loadData() {
        this.isLoading = true;
        this.isQuestionsLoading = true;
        this.isFragrancesLoading = true;
        this.isInvitesLoading = true;
        this.isQuizResultsLoading = true;
        this.isAdjectivesLoading = true;
        this.isProvidersLoading = true;

        await this.$store.dispatch("providersModule/fetchProviders").then((pResults) => {
            this.initProviderDetailsByRole();
            console.log('Providers retrieved...');
            console.log(pResults);
            this.isProvidersLoading = false;
            this.initUserCountByRole();
            this.isUsersLoading = false;
        });
     
        await this.$store.dispatch("fragrancesExtendedModule/fetchFragrancesExtended").then((fResults) => {
            console.log('Fragrances retrieved...');
            console.log(fResults);
            this.isFragrancesLoading = false;
        });
        await this.$store.dispatch("questionsModule/fetchQuestions").then((qResults) => {
            console.log('Questions retrieved...');
            console.log(qResults);
            this.isQuestionsLoading = false;
        });
        await this.$store.dispatch("adjectivesModule/fetchAdjectives").then((aResults) => {
            console.log('Adjectives retrieved...');
            console.log(aResults);
            this.isAdjectivesLoading = false;
        });
        
        await this.$store.dispatch("invitesModule/fetchInvites").then((iResults) => {
            this.initInviteCountByRole();
            console.log('Invites retrieved...');
            console.log(iResults);
            this.isInvitesLoading = false;
        });
        await this.$store.dispatch("quizResultsModule/fetchQuizResults").then((qrResults) => {
            this.initQuizResultCountByRole();
            console.log('Quiz Results retrieved...');
            console.log(qrResults);
            this.isQuizResultsLoading = false;
        });
    },
    initInviteCountByRole() {
        console.log(this.account);
        // get current role...
        // if admin, get all invites
        // if manager, get invites sent by provider
        // if user, get invites sent by user
        if (this.getCurrentUserInRole('Task.Admin')) {
            console.log('Admin...');
            this.inviteCount = this.totalInviteCount;
        } else if (this.getCurrentUserInRole('Task.Manager')) {
            console.log('Manager...');
            console.log(this.currentExtendedUser);
            this.inviteCount = this.inviteCountByProvider(this.currentUserProvider.id);
        } else if(this.account?.username){
            this.inviteCount = this.inviteCountBySalesperson(this.account.username);
            console.log('Salesperson...');
        }

        if (this.getCurrentUserInRole('Task.User')) {
            console.log('Task.User');
        }
    },
    initUserCountByRole() {
        // get current role...
        // if admin, get all users
        // if manager, get users by provider
        if (this.getCurrentUserInRole('Task.Admin')) {
            console.log('Admin...');
            this.userCount = this.totalUsersCount;
        } else if (this.getCurrentUserInRole('Task.Manager')) {
            console.log('Manager...');
            console.log(this.currentUserProvider.id);
            //this.userCount = this.totalUsers.filter(user => user.settings.provider == this.currentExtendedUser.provider);
            this.userCount = this.userCountByProvider(this.currentUserProvider.id);
        } 
    },
    initQuizResultCountByRole() {
        // get current role...
        // if admin, get all results
        // if manager, get results sent by provider
        // if user, get results sent by user
        if (this.getCurrentUserInRole('Task.Admin')) {
            console.log('Admin...');
            this.resultCount = this.totalQuizResultCount;
        } else if (this.getCurrentUserInRole('Task.Manager')) {
            console.log('Manager...');
            this.resultCount = this.resultCountByProvider(this.currentUserProvider);
        } else if(this.account?.username){
            this.resultCount = this.resultCountBySalesperson(this.account.username);
            console.log('Salesperson...');
        } 
    },
    initProviderDetailsByRole() {
        this.currentUserProvider = this.providerDetail(this.currentExtendedUser.provider);
        console.log(this.currentUserProvider);
        this.providerFragranceCount = this.currentUserProvider.fragrances.length + 1;
    },
    async reSyncData() {
        await this.$store.dispatch("usersModule/fetchAllUsers").then(() => {
            this.loadData();
        })
    },
    clearStorage() {
        localStorage.removeItem("Prolitec-VSE");
    },
  }
};
</script>
