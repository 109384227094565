<template>    
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>   
    <ul class="navbar-nav justify-content-left align-items-center">
        <SignInOut></SignInOut>
          <li v-if="isUserSignedIn" class="inline-list-item me-3 nav-item">
              <router-link v-if="$route.matched.some(({ name }) => name != 'Dashboard')" to="/dashboard" class="link link-primary ms-2">Dashboard</router-link>
          </li>
           <li class="inline-list-item me-3 nav-item"><a href="mailto:vsesupport@prolitec.com">VSE Application Support</a></li>
     </ul>
     <ul class="navbar-nav justify-content-end align-items-center">  
        <li class="nav-item icon16">
          <a title="Change Theme" class="pointer" @click="selectTheme"><BIconCircleHalf /></a>
        </li>
        <li class="nav-item">
            <!-- <select class="selectpicker ms-2" data-width="fit" onchange="translateLanguage(this.value);">
                <option data-content='<span class="fi fi-af"></span> Afrikaans' value="Afrikaans">Afrikaans</option>
                <option data-content='<span class="fi fi-al"></span> Albanian' value="Albanian">Albanian</option>
                <option data-content='<span class="fi fi-ar"></span> Arabic' value="Arabic">Arabic</option>
                <option data-content='<span class="fi fi-am"></span> Armenian' value="Armenian">Armenian</option>
                <option data-content='<span class="fi fi-az"></span> Azerbaijani' value="Azerbaijani">Azerbaijani</option>
            </select> -->
            <div id="google_translate_element" class="ms-2"></div>
        </li>
        <!--   
        <li class="nav-item">
          <a class="nav-link link link-primary"
                v-if="!account"
                @click="SignIn"
                rel="noopener noreferrer"
              >
              Sign In
          </a>
          <a class="nav-link" v-else @click="SignOut" href="#" rel="noopener noreferrer">
            Sign Out <BIconBoxArrowRight />
          </a>
        </li>-->
      </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import SignInOut from './SignInOut.vue';
export default {
  name: 'HeaderBar',
  components: {
        SignInOut
    },
  data() {
    return {
      signin: 'https://microsoft.com',
    };
  },
  async created() {
    // this.$msalInstance = new PublicClientApplication(
    //   this.$store.state.msalConfig,
    // );
  },
  mounted() {
    // const accounts = this.$msalInstance.getAllAccounts();
    // if (accounts.length == 0) {
    //   return;
    // }
    // this.$store.commit('setAccount', accounts[0]);
    // this.$emitter.emit('login', this.account);
  },
  computed: {
        ...mapGetters({
            isUserSignedIn: 'isSignedInUser'
        }),
    },
  methods: {
    selectTheme() {
        this.$store.commit('uiModule/TOGGLE_THEME');
    }
  },
};

// function handleResponse(response) {
//     console.log('response: ' + response);
//     if (response !== null) {
//         this.$store.commit('setAccount', response.account);
//         //accountId = response.account.homeAccountId;
//         // Display signed-in user content, call API, etc.
//     } else {
//         // In case multiple accounts exist, you can select
//         console.log(this.$msalInstance);
//         const currentAccounts = this.$msalInstance.getAllAccounts();

//         if (currentAccounts.length === 0) {
//             // no accounts signed-in, attempt to sign a user in
//             this.$msalInstance.loginRedirect({scopes: ["User.ReadWrite"]});
//         } else if (currentAccounts.length > 1) {
//             // Add choose account code here
//         } else if (currentAccounts.length === 1) {
//             this.$store.commit('setAccount', currentAccounts[0]);
//             console.log('header-bar-links component...sign in');
//             console.log(this.$store.state.account);
//             this.$emitter.emit('login', this.$store.state.account);
//             //accountId = currentAccounts[0].homeAccountId;
//         }
//     }
// }
</script>
